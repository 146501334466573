<template>
  <div class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden" id="email-app">
    <vx-card>
      <vs-table :data="blogs" max-items="7" pagination search>


        <template slot="header">
          <vs-button @click="createArticle" class="" color="danger" to="/a/blogs/create" type="gradient">Create an
            Article
          </vs-button>
        </template>

        <template slot="thead">
          <vs-th sort-key="id">ID</vs-th>
          <vs-th sort-key="title">Title</vs-th>
          <vs-th sort-key="author">Author</vs-th>
          <vs-th sort-key="published">Published</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].id">
              <router-link :to="`/a/blogs/${data[indextr].id}`">
                {{ data[indextr].id }}
              </router-link>
            </vs-td>

            <vs-td :data="data[indextr].title">
              {{ data[indextr].title }}
            </vs-td>

            <vs-td :data="data[indextr].author">
              <router-link :to="`/a/users/${data[indextr].author}`">
                {{ data[indextr].author_name || `loading...` }}
              </router-link>
            </vs-td>

            <vs-td :data="data[indextr].published">
              {{ data[indextr].published }}
            </vs-td>

          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
export default {
  name: 'BlogList',
  data() {
    return {
      blogs: [],
    };
  },
  methods: {
    createArticle() {
      this.$router.push('/a/blogs/create');
    },
  },
  created() {
    this.$vs.loading();

    this.$http.get('blog?amount=500')
      .then(response => {
        this.$vs.loading.close();

        const articles = response.data.data;

        this.blogs = articles.map(article => {

          return {
            id: article.id,
            title: article.title,
            author: article.author,
            published: (new Date(article.created)).toLocaleString(),
          };

        }).reverse();

        this.blogs
          .map(article => article.author)
          .filter((value, index, self) => self.indexOf(value) === index)
          .forEach(async author => {
            const response = await this.$http.get(`users/${author}`);
            const data = response.data.data;

            const name = `${data.first_name} ${data.last_name}`;

            this.blogs = this.blogs.map(article => {
              if (article.author === author) {
                article.author_name = name;
              }

              return article;
            });

          });

      })
      .catch(exception => {
        this.$vs.loading.close();

        return this.$vs.notify({
          title: 'Failed to load blog',
          text: 'An unknown error occurred while loading the blog list. This has been reported.',
          color: 'danger',
          position: 'top-right',
        });
      });

  },
};
</script>
